<template>
  <detail-layout ref="layout"
                 :crud-headers="crudHeaders"
                 :order-id="orderId"
                 :order-details="orderDetails"
                 :is-pending="isPending"
                 :is-pending-submit="isPendingSubmit"
                 :is-pending-remove="isPendingRemove"
                 @approve="approve(orderId, true)"
                 @change="reload">
    <template #headers>
      <router-link :to="{name: 'my-orders.index'}" class="secondary--text">{{ $t('orders.myOrders.pageTitle') }}</router-link>
      / {{ $t('orders.details.pageTitle', [orderId]) }}
    </template>
  </detail-layout>
</template>

<script>
import { EventBus } from '@/application/EventBus.js';
import { OrderDetail } from '@/application/models/Order.js';
import { approve, show } from '@/modules/orders/api/order.js';
import DetailLayout from '@/modules/orders/components/DetailLayout.vue';
import { remove } from '@/modules/shoppingCart/api';
import { i18n } from '@/plugins';
import { mapGetters } from 'vuex';

export default {
  name: 'MyOrdersDetail',
  components: { DetailLayout },
  data() {
    return {
      orderDetails: new OrderDetail(),
      isPending: false,
      isPendingSubmit: false,
      isPendingRemove: [],
      timeoutIndexRequest: null, //@TODO is this used?
    };
  },
  watch: {
    async currentStoreId() {
      this.$router.push({name: 'my-orders.index'})
    },
  },
  computed: {
    ...mapGetters('settings', { currentStoreId: 'getCurrentStoreId' }),
    orderId() {
      return this.$route.params.orderId;
    },
    crudHeaders() {
      return [
        {
          value: 'image',
          text: '',
          width: 132,
        },
        {
          value: 'name',
          text: this.$t('shoppingCart.headers.itemName'),
          cellClass: 'py-3',
        },
        {
          value: 'sku',
          text: this.$t('shoppingCart.headers.itemCode'),
          cellClass: 'py-3',
        },
        {
          value: 'quantity',
          text: this.$t('shoppingCart.headers.quantity'),
          cellClass: 'py-3',
        },
        {
          value: 'price',
          text: this.$t('shoppingCart.headers.salesPrice'),
          cellClass: 'py-3',
        },
        {
          value: 'linePrice',
          text: this.$t('shoppingCart.headers.linePrice'),
          cellClass: 'py-3',
        },
        {
          value: 'actions',
          text: '',
          width: "0%",
          sortable: false
        },
      ];
    },
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { data: { data } } = await show(to.params.orderId);
      next((vm) => {
        vm.setOrderDetails(data);
      });
    } catch (e) {
      EventBus.$emit('snackbar', {
        color: 'error',
        text: i18n.t('errors.defaultErrorWrapper', [i18n.t('errors.fetchOrderData')]),
      });
      next();
    }
  },
  methods: {
    approve,
    async reload() {
      const { data: { data } } = await show(this.$route.params.orderId);
      this.setOrderDetails(data);
    },
    setOrderDetails(data) {
      this.orderDetails = new OrderDetail().mapResponse(data);
    },
    async indexRequest(...args) { //@TODO is this used?
      this.isPending = true;
      try {
        const { data: { data } } = await show(this.orderId, ...args);
        this.setOrderDetails(data);
      } catch (e) {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.fetchOrderData')]),
        });
      }
      this.isPending = false;
      return {
        data: {
          data: this.orderDetails.orderLines,
        },
      };
    },
  },
};
</script>
